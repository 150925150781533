import * as React from 'react';
import Helmet from 'react-helmet';

import favicon from '../assets/favicon.ico';

const DefaultHelmet = () => {
  return (
    <Helmet titleTemplate="%s | FAYD">
      <title>Find Your Style, Find Your Price</title>
      {
        // Request that search engines don't index (don't display in search results)
        // and don't follow (don't crawl links on the page).
      }
      <meta name="robots" content="noindex, nofollow" data-testid="robots"></meta>
      <meta name="description" content="FAYD Freestyle Search empowers you to find your style."></meta>
      <meta name="keywords" content="fayd,freestyle search,find your style"></meta>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
      <link rel="icon" href={favicon} />
    </Helmet>
  );
};

export default DefaultHelmet;
